import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import PaymentTypeSelect from '../../common/PaymentTypeSelect';
import AccountTypesSelect from '../../common/AccountTypesSelect';
import FileInput from '../../common/FileInput';
import UserDefinedFieldEditor from '../../common/UserDefinedFieldEditor';
import Checkbox from '../../common/Checkbox';
import ShoppingCart from '../orders/ShoppingCart';
import { UtilsService } from '../../services/UtilsService';
import { DEFAULT_HOLD_REASONS } from '../../constants/Constants';
import DatePicker from "react-datepicker";

class CustomerMassImport extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            accountsFile: null,
            userDefinedFields: [],
            defaultTermsId: 5,
            salesmanId: 14,
            placeSampleOrders: false,
            order: null,
            holdReason: DEFAULT_HOLD_REASONS[0],
            otherHoldReason: ""
        }
        this.handleDefaultTermsChange = this.handleDefaultTermsChange.bind(this);
        this.handleSalesmanChange = this.handleSalesmanChange.bind(this);
        this.handleAccountsFileChange = this.handleAccountsFileChange.bind(this);
        this.handleHoldReasonChange = this.handleHoldReasonChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handlePlaceSampleOrdersChange = this.handlePlaceSampleOrdersChange.bind(this);
        this.handleHoldRelaseDateChange = this.handleHoldRelaseDateChange.bind(this);
        this.handleOtherHoldReasonChange = this.handleOtherHoldReasonChange.bind(this);
        this.handleCartItemAdded = this.handleCartItemAdded.bind(this);
        this.submit = this.submit.bind(this);
        this.utils = new UtilsService();
    }

    componentDidMount() {
        this.getUserDefinedFields();
    }

    componentWillUnmount() {
        if (this.state.order) {
            this.http.get("/api/Orders/DeleteMockOrder", { orderId: this.state.order.orderID });
        }
    }

    getUserDefinedFields() {
        var params = {
            entity: "Account"
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            // match values with fields
            for (var i = 0; i < data.length; i++) {
                if (this.props.category && this.props.category.userDefinedFields) {
                    var categoryFieldValue = this.props.category.userDefinedFields.find(f => f.userDefinedFieldID === data[i].userDefinedFieldID);
                    if (data[i].selection === "Single") {
                        data[i].value = categoryFieldValue ? categoryFieldValue.userDefinedFieldOptionID : -1;
                    }
                    else {
                        data[i].value = categoryFieldValue ? categoryFieldValue.userDefinedFieldOptionIDs : [];
                    }
                }
                else {
                    if (data[i].selection === "Single") {
                        data[i].value = -1;
                    }
                    else {
                        data[i].value = [];
                    }
                }
            }
            const fieldsToDisplay = ["Specialty", "Category", "Tag (Account)"];
            data = data.filter(d => fieldsToDisplay.indexOf(d.name) >= 0);
            this.setState({ userDefinedFields: data }, this.notifyChange);
        });
    }

    handleDefaultTermsChange(value) {
        this.setState({
            defaultTermsId: value
        })
    }

    handleSalesmanChange(value) {
        this.setState({
            salesmanId: value
        })
    }

    handleAccountsFileChange(file) {
        console.log(file);
        this.setState({
            accountsFile: file
        });
    }

    handleFieldChange(index, e) {
        var userDefinedFields = this.state.userDefinedFields;
        userDefinedFields[index].value = e;
        this.setState({
            userDefinedFields: userDefinedFields
        });
    }

    handleHoldReasonChange(e) {
        this.setState({
            holdReason: e.currentTarget.value
        });
    }

    handleHoldRelaseDateChange(value) {
        this.setState({
            holdReleaseDate: value
        });

    }

    handleOtherHoldReasonChange(e) {
        this.setState({
            otherHoldReason: e.currentTarget.value
        });
    }

    handlePlaceSampleOrdersChange(checked) {
        this.setState({
            placeSampleOrders: checked
        }, async () => {
            if (this.state.placeSampleOrders && this.state.order === null) {
                const data = await this.http.get("/api/Orders/CreateMockOrder");
                this.setState({
                    order: data
                });
            }
        })
    }

    handleCartItemAdded(order) {
        this.setState({
            order: order
        });
    }

    submit() {
        var selectedUserDefinedFields = this.utils.prepareUserDefinedFieldParams(this.state.userDefinedFields);
        var params = {
            model: JSON.stringify({
                userDefinedFields: selectedUserDefinedFields,
                defaultTermsId: this.state.defaultTermsId,
                salesmanId: this.state.salesmanId,
                placeSampleOrders: this.state.placeSampleOrders,
                orderId: this.state.order ? this.state.order.orderID : -1,
                holdReason: this.state.holdReason === "Other" ? this.state.otherHoldReason : this.state.holdReason,
                holdReleaseDate: this.state.holdReleaseDate,
            }),
            operation: "MassImportAccounts",
        }
        this.http.upload('/api/FileUpload/UploadFile/', this.state.accountsFile, params).then(() => {
            this.utils.info("It will take some time to import the accounts. You will receive an email once the process is complete.");
            this.setState({
                accountsFile: null,
                order: null,
                placeSampleOrders: false
            })
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Mass Import</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-body">
                                <div className="row m-0">
                                    <div className="form-group col-md-4">
                                        <label>Accounts File</label><br />
                                        <FileInput label="Select File" onChange={this.handleAccountsFileChange}></FileInput>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Default Terms</label>
                                        <PaymentTypeSelect value={this.state.defaultTermsId} onChange={this.handleDefaultTermsChange}></PaymentTypeSelect>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Account Type</label>
                                        <AccountTypesSelect value={this.state.salesmanId} onChange={this.handleSalesmanChange}></AccountTypesSelect>
                                    </div>
                                    {this.state.userDefinedFields.map((field, index) =>
                                        <div key={field.userDefinedFieldID} className="form-group col-md-4">
                                            <UserDefinedFieldEditor field={field} value={field.value} onChange={(e) => this.handleFieldChange(index, e)}></UserDefinedFieldEditor>
                                        </div>
                                    )}
                                    <div className="form-group col-md-12">
                                        <Checkbox label="Place Sample Orders" checked={this.state.placeSampleOrders} onChange={this.handlePlaceSampleOrdersChange}></Checkbox>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>On Hold Reason</label>
                                        <select className="form-control" value={this.state.holdReason} onChange={this.handleHoldReasonChange}>
                                            {DEFAULT_HOLD_REASONS.map(reason =>
                                                <option key={reason} value={reason}>{reason}</option>
                                            )}
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {this.state.holdReason === "Other" &&
                                        <div className="form-group col-md-4">
                                            <label>Other Reason</label>
                                            <input type="text" className="form-control" value={this.state.otherHoldReason} onChange={this.handleOtherHoldReasonChange}></input>
                                        </div>
                                    }
                                    <div className="form-group col-md-4">
                                        <label>Expected Release</label>
                                        <DatePicker className="form-control" selected={this.state.holdReleaseDate} onChange={this.handleHoldRelaseDateChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.placeSampleOrders && this.state.order &&
                    <React.Fragment>
                        <div className="row">
                            {/* Products */}
                            <div className="col-md-12">
                                <div className="card full-height">
                                    <div className="card-header">
                                        <div className="card-head-row">
                                            <div className="card-title">Products</div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {this.state.order.shoppingCart != null &&
                                            <ShoppingCart order={this.state.order} shoppingCart={this.state.order.shoppingCart} onItemAdded={this.handleCartItemAdded}></ShoppingCart>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* End Products */}
                        </div>
                    </React.Fragment>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-body pull-right">
                                <button disabled={!this.state.accountsFile} className="btn btn-primary btn-default pull-right mr-2" onClick={this.submit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerMassImport;
