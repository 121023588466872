import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import FileInput from '../../common/FileInput';
import Report from '../../common/Report';
import CompanySelect from '../../common/CompanySelect';

class WalmartReconciliation extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            paymentFile: null,
            mismatchedOnly: false,
            splitOnly: false,
            companyId: 2,
            report: null
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handlePaymentFileChange = this.handlePaymentFileChange.bind(this);
        this.handleMismatchedOnlyChange = this.handleMismatchedOnlyChange.bind(this);
        this.handleSplitOnlyChange = this.handleSplitOnlyChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.getReport = this.getReport.bind(this);
    }

    componentDidMount() {
    }

    handlePaymentFileChange(file) {
        this.setState({
            paymentFile: file
        });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleMismatchedOnlyChange(checked) {
        this.setState({ mismatchedOnly: checked });
    }

    handleSplitOnlyChange(checked) {
        this.setState({ splitOnly: checked });
    }

    getReport() {
        var params = {
            companyId: this.state.companyId,
            mismatchedOnly: this.state.mismatchedOnly,
            splitOnly: this.state.splitOnly,
            operation: "ReconcileWalmartOrders",
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        this.http.upload('/api/FileUpload/UploadFile/', this.state.paymentFile, params).then(data => {
            this.setState({
                report: data
            })
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Reconcile Walmart Orders</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Payment File</label>
                                        <br />
                                        <FileInput label="Select File" onChange={this.handlePaymentFileChange}></FileInput>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Company</label>
                                        <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange}>
                                        </CompanySelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>&nbsp;</label>
                                        <Checkbox label="Display mismatched orders only" checked={this.state.mismatchedOnly} onChange={this.handleMismatchedOnlyChange}></Checkbox>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>&nbsp;</label>
                                        <Checkbox label="Display split orders only" checked={this.state.splitOnly} onChange={this.handleSplitOnlyChange}></Checkbox>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <button className="btn btn-primary mt-4" onClick={this.getReport}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="WalmartReconciliationReport" hasData={this.state.report.items && this.state.report.items.length > 0}>
                                <div className="form-row">
                                    <table className="table table-bordered table-striped mt-1 report-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={6}></th>
                                                <th className="separator"></th>
                                                <th colSpan={2}><h3>PowerD</h3></th>
                                                <th className="separator"></th>
                                                <th colSpan={7}><h3>Walmart</h3></th>
                                            </tr>
                                            <tr>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("WalmartOrderId")}>WALMART ORDER #</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderId")}>ORDER #</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Date")}>DATE</th>
                                                <th className="sortable" scope="col">SPLIT FULFILLMENT</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Customer")}>CUSTOMER</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("State")}>STATE</th>
                                                <th className="separator"></th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDProductCharges")}>PRODUCT CHARGES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDSalesTax")}>SALES TAX</th>
                                                <th className="separator"></th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.ProductCharges")}>PRODUCT CHARGES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.SalesTax")}>SALES TAX</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.OtherTax")}>OTHER TAXES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.Commission")}>COMMISION</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.Fee")}>FEES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.TotalFees")}>TOTAL FEES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WalmartOrder.Total")}>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.report.items && this.state.report.items.map(item =>
                                                <tr key={item.orderID}>
                                                    <td className={(!item.walmartOrderId ? "bg-danger" : "") + ((item.walmartOrder && item.walmartOrder.isSplit ? " bg-warning" : ""))}>{item.walmartOrderId}</td>
                                                    <td className={!item.orderNumber ? "bg-danger" : ""}><Link to={"/order/" + item.orderId} target="_blank" rel="noopener noreferrer">{item.orderNumber}</Link></td>
                                                    <td>{item.date}</td>
                                                    <td className={(item.walmartOrder && item.walmartOrder.isSplit ? " bg-warning" : "")}>
                                                        {item.walmartOrder ? item.walmartOrder.splitDates : ""}
                                                    </td>
                                                    <td><Link to={"/customer/" + item.customerId} target="_blank">{item.customer}</Link></td>
                                                    <td>{item.state}</td>
                                                    <td className="separator"></td>
                                                    <td className={"currency " + (item.powerDProductCharges !== item.walmartOrder.productCharges ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDProductCharges, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDSalesTax !== item.walmartOrder.salesTax ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDSalesTax, item.powerDCurrency)}</td>
                                                    <td className="separator"></td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.productCharges, item.walmartOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.salesTax, item.walmartOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.otherTax, item.walmartOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.commission, item.walmartOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.fee, item.walmartOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.totalFees, item.walmartOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.walmartOrder.total, item.walmartOrder.currency)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tbody>
                                            <tr className="bg-dark reportFooter">
                                                <th colSpan={6}></th>
                                                <td className="separator"></td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDProductCharges, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDSalesTax, this.state.report.currency)}</td>
                                                <td className="separator"></td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartProductCharges, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartSalesTax, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartOtherTax, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartCommission, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartFee, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartTotalFees, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.walmartTotal, this.state.report.currency)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default WalmartReconciliation;
