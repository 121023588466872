
import React, { Component } from 'react';
import { Route } from "react-router-dom";
import OrderList from './pages/orders/OrderList'
import OrderDetail from './pages/orders/OrderDetail'
import AmazonOrderList from './pages/orders/AmazonOrderList';
import FulfillList from './pages/orders/FulfillList';
import InvoiceList from './pages/invoices/InvoiceList';
import Dashboard from './pages/dashboard/Dashboard';
import CustomerList from './pages/customers/CustomerList';
import StartNewOrder from './pages/orders/StartNewOrder';
import AddOrder from './pages/orders/AddOrder';
import AutoShippedOrderList from './pages/orders/AutoShippedOrderList';
import WebFulfillList from './pages/orders/WebFulfillList';
import AddCustomer from './pages/customers/AddCustomer';
import CustomerHistory from './pages/customers/CustomerHistory';
import CustomerComments from './pages/customers/CustomerComments';
import CustomerProductSearch from './pages/customers/CustomerProductSearch';
import InvoiceReport from './pages/reports/InvoiceReport';
import ProductSalesReport from './pages/reports/ProductSalesReport';
import SalesTaxesReport from './pages/reports/SalesTaxesReport';
import InventoryReport from './pages/reports/InventoryReport';
import CustomerCommentsReport from './pages/reports/CustomerCommentsReport';
import CustomerCategoryReport from './pages/reports/CustomerCategoryReport';
import AmazonOrdersReport from './pages/reports/AmazonOrdersReport';
import AddProduct from './pages/inventory/AddProduct';
import ProductList from './pages/inventory/ProductList';
import ProductDetail from './pages/inventory/ProductDetail';
import AddBatch from './pages/inventory/AddBatch';
import BatchList from './pages/inventory/BatchList';
import ASINList from './pages/inventory/ASINList';
import ASINDetail from './pages/inventory/ASINDetail';
import AddASIN from './pages/inventory/AddASIN';
import AmazonReconciliation from './pages/accounting/AmazonReconciliation';
import AmazonIntegrationLog from './pages/orders/AmazonIntegrationLog';
import ChangePassword from './pages/account/ChangePassword';
import AuthorizationLog from './pages/admin/AuthorizationLog';
import ErrorLog from './pages/admin/ErrorLog';
import EDILog from './pages/admin/EDILog';
import CustomerHold from './pages/admin/CustomerHold';
import BWExport from './pages/invoices/BWExport';
import UserList from './pages/users/UserList';
import AddUser from './pages/users/AddUser';
import EditUser from './pages/users/EditUser';
import CouponList from './pages/admin/CouponList';
import AddCoupon from './pages/admin/AddCoupon';
import EditCoupon from './pages/admin/EditCoupon';
import CategorySpecialtyGrid from './pages/admin/CategorySpecialtyGrid';
import CategoryList from './pages/admin/CategoryList';
import EditCategory from './pages/admin/EditCategory';
import AddCategory from './pages/admin/AddCategory';
import ChangeOrderStatus from './pages/admin/ChangeOrderStatus';
import ShippingCostList from './pages/admin/ShippingCostList';
import FulfillOrder from './pages/orders/FulfillOrder';
import ContactDetail from './pages/crm/ContactDetail';
import AddContact from './pages/crm/AddContact';
import AddUserDefinedField from './pages/crm/AddUserDefinedField';
import EditUserDefinedField from './pages/crm/EditUserDefinedField';
import UserDefinedFieldList from './pages/crm/UserDefinedFieldList';
import ContactList from './pages/crm/ContactList';
import ReportDataList from './pages/admin/ReportDataList';
import AccountList from './pages/crm/AccountList';
import AddAccount from './pages/crm/AddAccount';
import AccountDetail from './pages/crm/AccountDetail';
import CustomerSearch from './pages/customers/CustomerSearch';
import CustomerTypeList from './pages/admin/CustomerTypeList';
import EditCustomerType from './pages/admin/EditCustomerType';
import AddCustomerType from './pages/admin/AddCustomerType';
import WalmartOrderList from './pages/orders/WalmartOrderList';
import MonthlyInventoryReports from './pages/reports/MonthlyInventoryReports';
import Settings from './pages/admin/Settings';
import SmtpAccountList from './pages/admin/SmtpAccountList';
import AddSmtpAccount from './pages/admin/AddSmtpAccount';
import EditSmtpAccount from './pages/admin/EditSmtpAccount';
import CompanyEmailSendingAccounts from './pages/admin/CompanyEmailSendingAccounts';
import OnHoldList from './pages/orders/OnHoldList';
import ProductOrder from './pages/inventory/ProductOrder';
import LotReport from './pages/reports/LotReport';
import RecentlyFulfilledOrders from './pages/orders/RecentlyFulfilledOrders';
import FedexShipmentLog from './pages/admin/FedexShipmentLog';
import FedExEndOfDayReport from './pages/reports/FedExEndOfDayReport';
import PaperlessFulfillList from './pages/orders/PaperlessFulfillList';
import SalesByRepsReport from './pages/reports/SalesByRepsReport';
import CouponsReport from './pages/reports/CouponsReport';
import CsoProductSalesReport from './pages/reports/CsoProductSalesReport';
import CsoBenchmarkReport from './pages/reports/CsoBenchmarkReport';
import SalesByHoldReasonReport from './pages/reports/SalesByHoldReasonReport';
import DashboardReport from './pages/reports/DashboardReport';
import CustomerMassImport from './pages/customers/CustomerMassImport';
import WalmartReconciliation from './pages/accounting/WalmartReconciliation';

class AppRoutes extends Component {

    render() {        
        return (
            <React.Fragment>
                <Route exact path="/" component={Dashboard} />
                <Route path="/orders" exact={true} component={OrderList} />
                <Route path="/order/:id" exact={true} component={OrderDetail} />
                <Route path="/orders/start-new" exact={true} component={StartNewOrder} />
                <Route path="/orders/add/:customerId" exact={true} component={AddOrder} />
                <Route path="/orders/auto-ship" exact={true} component={AutoShippedOrderList} />
                <Route path="/orders-on-hold" exact={true} component={OnHoldList} />
                <Route path="/recently-fulfilled" exact={true} component={RecentlyFulfilledOrders} />
                <Route path="/fulfillment" exact={true} component={FulfillList} />
                <Route path="/fulfillment-paperless" exact={true} component={PaperlessFulfillList} />
                <Route path="/fulfillment/:id" exact={true} component={FulfillOrder} />
                <Route path="/web-fulfillment" exact={true} component={WebFulfillList} />
                <Route path="/amazon-orders" exact={true} component={AmazonOrderList} />
                <Route path="/walmart-orders" exact={true} component={WalmartOrderList} />
                <Route path="/amazon-orders/integration-log" exact={true} component={AmazonIntegrationLog} />
                <Route path="/walmart-orders/reconcile" exact={true} component={WalmartReconciliation} />
                <Route path="/invoices" exact={true} component={InvoiceList} />
                <Route path="/invoices/bw-export" exact={true} component={BWExport} />
                <Route path="/customers" exact={true} component={CustomerList} />
                <Route path="/customers/add" exact={true} component={AddCustomer} />
                <Route path="/customers/mass-import" exact={true} component={CustomerMassImport} />
                <Route path="/customer/:customerId" exact={true} component={AccountDetail} />
                <Route path="/customer/history/:id" exact={true} component={CustomerHistory} />
                <Route path="/customers/comments" exact={true} component={CustomerComments} />
                <Route path="/customers/product-search" exact={true} component={CustomerProductSearch} />
                <Route path="/reports/invoice-report" exact={true} component={InvoiceReport} />
                <Route path="/reports/promotions-report" exact={true} component={CouponsReport} />
                <Route path="/reports/product-sales-report" exact={true} component={ProductSalesReport} />
                <Route path="/reports/cso-product-sales-report" exact={true} component={CsoProductSalesReport} />
                <Route path="/reports/cso-benchmark-report" exact={true} component={CsoBenchmarkReport} />
                <Route path="/reports/dashboard-report" exact={true} component={DashboardReport} />
                <Route path="/reports/sales-by-reps-report" exact={true} component={SalesByRepsReport} />
                <Route path="/reports/sales-by-hold-reason-report" exact={true} component={SalesByHoldReasonReport} />
                <Route path="/reports/fedex-end-of-day-report" exact={true} component={FedExEndOfDayReport} />
                <Route path="/reports/sales-taxes-report" exact={true} component={SalesTaxesReport} />
                <Route path="/reports/inventory-report" exact={true} component={InventoryReport} />
                <Route path="/reports/lot-report" exact={true} component={LotReport} />
                <Route path="/reports/monthly-inventory-reports" exact={true} component={MonthlyInventoryReports} />
                <Route path="/reports/customer-comments-report" exact={true} component={CustomerCommentsReport} />
                <Route path="/reports/customer-category-report" exact={true} component={CustomerCategoryReport} />
                <Route path="/amazon-orders/report" exact={true} component={AmazonOrdersReport} />
                <Route path="/amazon-orders/reconcile" exact={true} component={AmazonReconciliation} />
                <Route path="/inventory" exact={true} component={ProductList} />
                <Route path="/inventory/product-order" exact={true} component={ProductOrder} />
                <Route path="/inventory/add-product" exact={true} component={AddProduct} />
                <Route path="/inventory/add-batch" exact={true} component={AddBatch} />
                <Route path="/inventory/product/:id" exact={true} component={ProductDetail} />
                <Route path="/inventory/batch-list" exact={true} component={BatchList} />
                <Route path="/inventory/batch/:id" exact={true} component={AddBatch} />
                <Route path="/inventory/add-asin" exact={true} component={AddASIN} />
                <Route path="/inventory/asin-list" exact={true} component={ASINList} />
                <Route path="/inventory/asin/:id" exact={true} component={ASINDetail} />
                <Route path="/account/change-password" exact={true} component={ChangePassword}></Route>
                <Route path="/account/login" exact={true} component={ChangePassword}></Route>
                <Route path="/admin/authorization-log" exact={true} component={AuthorizationLog}></Route>
                <Route path="/admin/error-log" exact={true} component={ErrorLog}></Route>
                <Route path="/admin/shipment-log" exact={true} component={FedexShipmentLog}></Route>
                <Route path="/admin/edi-log" exact={true} component={EDILog}></Route>
                <Route path="/admin/customer-hold" exact={true} component={CustomerHold}></Route>
                <Route path="/admin/coupons" exact={true} component={CouponList}></Route>
                <Route path="/admin/settings" exact={true} component={Settings}></Route>
                <Route path="/admin/add-coupon" exact={true} component={AddCoupon}></Route>
                <Route path="/admin/coupons/:id" exact={true} component={EditCoupon}></Route>
                <Route path="/admin/smtp-accounts" exact={true} component={SmtpAccountList}></Route>
                <Route path="/admin/add-smtp-account" exact={true} component={AddSmtpAccount}></Route>
                <Route path="/admin/smtp-accounts/:id" exact={true} component={EditSmtpAccount}></Route>
                <Route path="/admin/company-email-accounts/" exact={true} component={CompanyEmailSendingAccounts}></Route>
                <Route path="/admin/category-specialty-grid" exact={true} component={CategorySpecialtyGrid}></Route>
                <Route path="/admin/categories" exact={true} component={CategoryList}></Route>
                <Route path="/admin/add-category" exact={true} component={AddCategory}></Route>
                <Route path="/admin/categories/:id" exact={true} component={EditCategory}></Route>
                <Route path="/admin/change-order-status" exact={true} component={ChangeOrderStatus}></Route>
                <Route path="/admin/shipping-costs" exact={true} component={ShippingCostList}></Route>
                <Route path="/admin/saved-reports" exact={true} component={ReportDataList}></Route>
                <Route path="/admin/customer-types" exact={true} component={CustomerTypeList}></Route>
                <Route path="/admin/customer-type/:id" exact={true} component={EditCustomerType}></Route>
                <Route path="/admin/add-customer-type" exact={true} component={AddCustomerType}></Route>
                <Route path="/users" exact={true} component={UserList}></Route>
                <Route path="/users/add" exact={true} component={AddUser}></Route>
                <Route path="/user/:id" exact={true} component={EditUser}></Route>
                <Route path="/crm/add-contact" exact={true} component={AddContact}></Route>
                <Route path="/crm/add-contact/:accountId" exact={true} component={AddContact}></Route>
                <Route path="/crm/contact-list" exact={true} component={ContactList}></Route>
                <Route path="/crm/contact-list/:reportId" exact={true} component={ContactList}></Route>
                <Route path="/crm/contact/:id" exact={true} component={ContactDetail}></Route>
                <Route path="/crm/add-user-defined-field" exact={true} component={AddUserDefinedField}></Route>
                <Route path="/crm/user-defined-field/:id" exact={true} component={EditUserDefinedField}></Route>
                <Route path="/crm/user-defined-fields" exact={true} component={UserDefinedFieldList}></Route>
                <Route path="/crm/add-account" exact={true} component={AddAccount}></Route>
                <Route path="/crm/account-list" exact={true} component={AccountList}></Route>
                <Route path="/crm/active-accounts-list" exact={true} component={AccountList}></Route>
                <Route path="/crm/account-list/:reportId" exact={true} component={AccountList}></Route>
                <Route path="/crm/account/:accountId" exact={true} component={AccountDetail}></Route>
                <Route path="/crm/customer-search" exact={true} component={CustomerSearch}></Route>
            </React.Fragment>
        );
    }
}
export default AppRoutes
