import { Component } from "react";
import moment from "moment";
import { HttpRequestService } from "../../services/HttpRequestService";

class WeatherForecast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forecast: [],
            title: "",
        }
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getForecast();
    }

    async getForecast() {
        // get lat long
        try {
            this.http.setLoading(true);
            var geocodeResponse = await fetch(`https://api.openweathermap.org/geo/1.0/zip?zip=${this.props.zipCode},US&appid=05306c19f3d61fd20fffd805a465289b`);
            var geocodeResult = await geocodeResponse.json();
            var forecastResponse = await fetch(`https://api.openweathermap.org/data/3.0/onecall?lat=${geocodeResult.lat}&lon=${geocodeResult.lon}&exclude=current,minutely,hourly&units=imperial&appid=05306c19f3d61fd20fffd805a465289b`);
            var forecastResult = await forecastResponse.json();
            var forecast = [];
            for (let i = 0; i < forecastResult.daily.length; i++) {
                var dayForecast = forecastResult.daily[i];
                forecast.push({
                    date: dayForecast.dt * 1000,
                    high: Math.round(dayForecast.temp.max),
                    low: Math.round(dayForecast.temp.min),
                    icon: `https://openweathermap.org/img/wn/${dayForecast.weather[0].icon}@2x.png`,
                    description: dayForecast.weather[0].description
                });
            };
            this.setState({
                forecast: forecast,
            });
        }
        finally {
            this.http.setLoading(false);
        }
    }

    render() {
        return (
            <div className="daily-list">
                {this.state.forecast.map(f =>
                    <div className="daily-list-item" key={f.date}>
                        <div className="date">
                            <p className="day">{moment(f.date).format('ddd')}</p>
                            <p>{moment(f.date).format('MM/DD')}</p>
                        </div>
                        <div className="icon">
                            <img height="48" width="48" src={f.icon} />
                        </div>
                        <div className="temp-phrase-wrapper">
                            <div className="temp">
                                <span className="temp-hi">{f.high}°</span>
                                <span className="temp-lo">{f.low}°</span>
                            </div>
                        </div>
                        <div className="phrase">
                            <p className="no-wrap" style={{ textTransform: "capitalize" }}>{f.description}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default WeatherForecast;
